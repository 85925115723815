@import "theme";

.default {
  border-bottom: none;
  background-color: transparent;
  color: #474F5E;
  height: 50px;
  padding: 0;
  padding-right: 1rem;
  padding-left: 1rem;
  display: flex;
  opacity: 1;
  -webkit-transition: opacity 1000ms linear;
  transition: opacity 1000ms linear;
}

.status {
  height: 16px;
  width: 20px;
  margin-right: 5px;
}

.mainSection {
  display: flex;
  align-items: center;

  width: 100%;
}

.hover-delivery-confirmed {
  &:hover {
    background-color: #E8F0A0;
  }
}

.hover-delivery-locked {
  &:hover {
    background-color: #FFFAED;
  }
}

.hover-delivery-unlocked {
  &:hover {
    background-color: #BFE8EB;
  }
}

@-webkit-keyframes bgcolor {
  0%   { background-color: $light-yellow; }
  100% { background-color: $light-blue; }
}
@-moz-keyframes bgcolor {
  0%   { background-color: $light-yellow; }
  100% { background-color: $light-blue; }
}
@-o-keyframes bgcolor {
  0%   { background-color: $light-yellow; }
  100% { background-color: $light-blue; }
}
@keyframes bgcolor {
  0%   { background-color: $light-yellow; }
  100% { background-color: $light-blue; }
}

.highlight {
  -webkit-animation: bgcolor 20s 1;
  animation: bgcolor 10s 1;
  -webkit-animation-direction: alternate;
  animation-direction: alternate;
}
