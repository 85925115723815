.base {
  min-height: 100vh;

  background-image: url('../../../public/sun.svg');
  background-repeat: no-repeat;
  background-position: top -420px right -420px;
}

.home {
  min-height: 100vh;
  background-image: url('../../../public/animated-default-bg.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: left bottom;
}
