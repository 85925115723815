@import "theme";

.card {
  border: none;
}

.confirmed {
  .cardHeader {
    height: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #E8F0A0;
    border: none;
    border-radius: 5px 5px 0 0;
  }
  .cardBody {
    background-color: #F9FCE0;
  }
}

.locked {
  .cardHeader {
    height: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: $light-yellow;
    border: none;
    border-radius: 5px 5px 0 0;
  }
  .cardBody {
    background-color: #FFFAED;
  }
}

.unlocked {
  .cardHeader {
    height: 50px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: #BFE8EB;
    border: none;
    border-radius: 5px 5px 0 0;
  }
  .cardBody {
    background-color: $light-blue;
  }
}

.cardBody {
  ul {
    li > div {
      border-bottom: 1px solid rgba(0, 0, 0, 0.125);
    }
    li:last-child > div {
      border-bottom: none;
    }
  }
}

.deliveryTime {
  font-family: HelveticaNeueLTStd-BdCn;
  font-size: 20px;
  margin-top: 10px;
  margin-right: 12px;
}
.closes, .empty {
  font-family: HelveticaNeueLTStd-Cn;
  font-size: 13px;
  line-height: 26px;
  margin-right: 12px;
}
.status, .empty {
  height: 1rem;
  margin-top: 3px;
}
.empty {
  text-transform:uppercase;
}
.moreBatch {
  background-color: transparent;
  height: 50px;
  padding-top: 12px;
}
.moreLink {
  color: #474F5E !important;
  text-decoration: underline !important;
  margin-right: 1rem;
  margin-left: 1rem;
}
