@import "theme";

.header {
  display: flex;
  height: 52px;
  width: 100%;
  margin-bottom: 15px;

  h1 {
    white-space: nowrap;
  }
}
