@import "theme";

.page-link {
  position: relative;
  display: block;
  padding: 0.5rem 0.75rem;
  line-height: 1.25;
  color: $primary;
  background-color: #fff;
  border: none;
  width: 40px;
  height: 40px;
  text-align: center;
  align-content: center;

  &.active {
    color: $font-color;
    font-weight: bold;
  }

  &:hover {
    background-color: $light-blue;
    color: $primary;
    text-decoration: underline;
  }

  &.previous, &.next {
    background-color: $primary;
    font-size: 0;
    padding: 0;
    background-size: 24px 24px;
    background-position: 50% 50%;
    background-repeat: no-repeat;

    .icon {
      filter: $filter-white;
    }

    &.disabled {
      background-color: white;
      border: solid 1px $primary;

      .icon {
        filter: $filter-primary;
        opacity: 0.5;
      }
    }
  }

  &.previous {
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
  }

  &.next {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
  }
}
