/*
  - Add negative margins and resize iframe so that the PowerBI padding (6px) will be outside the iframe container.
  - Hide iframe container overflow to hide the padding (6px).
   __________________________________
  | <iframe>                         |
  |    __________________________    |
  |   | .iframeContainer         |   |
  |   |                          |   |
  |   |                          |   |
  |   |__________________________|   |
  |                                  |
  |__________________________________|
*/

.iframeContainer {
  overflow: hidden;
  width: 100%;
  height: 100%;

  iframe {
    --powerBi-padding: 6px;
    --negative-margin: calc(var(--powerBi-padding) * -1);
    --extended-dimension: calc(100% + (var(--powerBi-padding) * 2));

    margin-left: var(--negative-margin);
    margin-top: var(--negative-margin);
    width: var(--extended-dimension) !important;
    height: var(--extended-dimension) !important;
  }
}
