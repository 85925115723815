@import "theme";

.repair-item {
  opacity: 1;
  -webkit-transition: opacity 500ms linear;
  transition: opacity 500ms linear;
}

.fadeOut {
  opacity: 0;
}

.overflow-x-scroll {
  overflow-x: scroll !important;
}

.repair-table {
  border: 2px solid $light-grey;
  border-radius: 8px;
  overflow: hidden;

  table {
    th {
      background-color: $primary-light;
    }
    td, th {
      padding: 0.47rem;
    }
    td {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    tbody {
      border-top: none !important;
    }
  }
}
