@import "theme";

.error {
  border: $secondary solid 1px !important;
  border-left-width: 2px !important;
  padding: 0 !important;
}

input {
  outline: none;
  border: none;
  height: 2.4rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
