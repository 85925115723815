@import "theme";

.batches-table {
  max-width: 700px;

  tr {
    border-bottom: solid 1px #BFE8EB;

    &:hover {
      background-color: #BFE8EB;
    }

    > :nth-child(2) {
      width: 90px;
    }
    > :last-child {
      width: 70px;
      text-align: right;
    }

    td {
      color: #474F5E;
      font: normal 16px/49px Helvetica Neue;
      padding: 0 20px 0 0 !important;

      &:last-child {
        padding-right: 0 !important;
      }

      a {
        color: #474F5E;
        &:hover {
          color: #474F5E;
          text-decoration: underline;
        }
      }
    }
  }
}
