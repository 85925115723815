@import "theme";

.errors {
  filter: $filter-secondary;
  line-height: 2rem;
  margin-bottom: 1.5rem;

  .header {
    font-weight: bold;
  }

  li {
    background: url("../../../public/icons/linear/0854-warning.svg") no-repeat -0px 5px transparent;
    list-style-type: none;
    margin-left: -2rem;
    padding: 0px 0px 0px 1.6rem;
  }
}
