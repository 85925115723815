.element {
  display: flex;
  align-items: center;
  color: #474F5E !important;
  height: 100%;
  width: 100%;
}

.nameContainer {
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
}

.name {
  white-space: nowrap;
}

.icon {
  background-color: #474F5E !important;
}

.clickable {
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    text-decoration-color: #474F5E;
  }
}

.strikeout {
  display: inline-block;
  position: absolute;
  left: 0;
  top: 50%;
  width: 0;
  border-top: 1px solid #474F5E;
  transition: width 0.5s ease-in;
}

.activeStrikeout {
  width: 100%;
}
