@import "theme";

.connectContainer {
  height: 100vh;
}
.lead {
  font-size: 16px;
  font-family: Helvetica Neue;
  top: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
}
.image {
  margin-bottom: 75px;
}
.text {
  margin-bottom: 3rem;
}
.icon {
  margin-right: .5rem;
  filter: invert(100%) sepia(0%) saturate(7491%) hue-rotate(241deg) brightness(107%) contrast(102%);
}
.button {
  color: #ffffff;
  font-family: HelveticaNeueLTStd-BdCn;
  font-size: 20px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  height: 60px;
  padding: 20px 1.5rem;
}
