.default {
  position: absolute;
  top: 0.25rem;
  left: 0.25rem;
  z-index: 1;
  text-align: left;
  font: normal normal normal 16px/19px Helvetica Neue;
  letter-spacing: 0px;
  color: #262A30;

  &.current {
    color: #ED691C !important;
    font-weight: bold;
  }
}
