@import "theme";

.dropdownMenu {
  padding-right: 14px;

  > button {
    height: 16px;
    width: 20px;
    padding: 0;
    border: none;
    background-color: transparent;

    &::before {
      content: none !important;
    }
  }
}

.iconOption {
  filter: invert(60%) sepia(93%) saturate(4890%) hue-rotate(156deg) brightness(96%) contrast(102%);
}

.disabled {
  cursor: not-allowed;
  filter: invert(90%) sepia(1%) saturate(0%) hue-rotate(156deg) brightness(94%) contrast(85%);
}

.dropdownOptions {
  margin-top: 25px !important;
  margin-right: -25px !important;
  padding-top: 0;
  padding-bottom: 0;
  background-color: $light-blue;
  border-color: #BFE8EB;

  li {
    button {
      color: $text-color;
      padding-top: 6px;
      padding-bottom: 6px;
      display: flex;
      align-items: center;

      &:hover {
        color: $text-color;
        background-color: #BFE8EB;
      }
    }
  }
}
