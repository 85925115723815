@import "theme";

.detailsContainer {
  color: $text-color;
  background-color: $light-blue;
  font: normal 16px/40px Helvetica Neue;
  overflow: hidden;

  padding: 37px 1rem 1rem;
  min-width: 320px;
  border-left: $light-grey solid 1px;

  // 20px here will offset the overall padding from Content.content
  width: calc(23% + 20px);
  margin-right: -20px;

  margin-top: -85px;
  margin-bottom: -20px;
  min-height: 100vh;

  z-index: 10;
}

.minWidth {
  min-width: 200.5px;
}

.title {
  font: bold 18px/26px HelveticaNeueLTStd-BdCn;
}

.deliveryAddress {
  line-height: 28px;
}

.list {
  display: flex;
  border-bottom: $light-grey solid 1px;

  > :first-child {
    width:40%;
  }
  > :last-child {
    width:60%;
    text-align: right;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &:nth-last-of-type(2) {
    border-bottom: none;
  }
}

.centerError {
  margin-top: 155px;
}

.noneSelected {
  margin-top: calc(50vh - 17px)
}
