@import "theme";

.row { width: 6ch; min-width: 5ch }
.ean { width: 15ch; min-width: 15ch }
.asin { width: 12ch; min-width: 12ch }
.other-reference { width: 20ch; min-width: 20ch }
.title { width: auto }
.retail-price { width: 11ch; min-width: 11ch }
.quantity { width: 7ch; min-width: 7ch }
.sku { width: 15ch; min-width: 15ch }
.grade { width: 3ch; min-width: 3ch }
.grade-remark { width: 20ch; min-width: 20ch }
.owner-reference { width: 20ch; min-width: 20ch }
.owner-price { width: 12ch; min-width: 12ch }
.return { width: 140px; min-width: 140px }
