@import "../theme";

.default {
  &.hovered {
    i {
      filter: $filter-secondary !important;
      cursor: pointer;
    }

    h1 {
      text-decoration: underline $secondary 1px;
      text-underline-offset: 3px;
    }
  }

  .help-icon {
    position: relative;
    filter: $filter-primary !important;
    display: inline-block;
    position: relative;
    line-height: 0px;
    top: -16px;
    margin-right: -12px;
    padding: 3px;
  }
}
