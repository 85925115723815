@import "theme";

.submit {
  margin-top: 1rem;
}

.submit-button {
  width: 164px;
  text-align: center;
}

.illustration {
  position: absolute;
  top: 70px;
  left: calc(100% - 330px);
}
