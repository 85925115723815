@import "theme";

.calendar-root {
  --text-color-light: rgba(0, 0, 0, 0.4);
  --text-color-primary: rgba(0, 0, 0, 0.7);
  --space-sm: 4px;
  --space-md: 8px;
  --space-lg: 16px;
  width: 100%;
  height: 100%;
}

/* | Mon | Tue | Wed | Thu | Fri | Sat | Sun */
.day-of-week-header-cell {
  background-color: var(--grid-foreground-color);
  padding: var(--space-md) 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  text-align: center;
  color: #DADCDF;
}

.days-of-week,
.days-grid {
  width: 100%;
  height: 30px;
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(14%, 1fr));
  grid-column-gap: var(--grid-gap);
  grid-row-gap: var(--grid-gap);
  grid-auto-rows: 1fr;
}

.days-grid {
  height: 100%;
  position: relative;
  border: 1px solid #DADCDF !important;
  border-bottom: 0 !important;
  border-radius: 8px;
  background-color: var(--grid-background-color);
}

/* Position the day label within the day cell */
.day-grid-item-container .day-grid-item {
  color: var(--text-color-primary);
  padding: 4px 4px 0 4px;
  flex-shrink: 0;
  font-weight: bold;
  float: left;
}

.hover-element {
  background: $light-blue 0% 0% no-repeat padding-box;
  cursor: pointer;
}

.month-nav-arrow-buttons > div {
  float: left;
}

.morning-row {
  display: flex;
  margin-top: 5px;
}

.afternoon-row {
  display: flex;
  margin-top: 5px;
}

.morning {
  float: left;
  height: 50%;
  margin-right: 5px;
  margin-left: 0;
}

.morning-card {
  background: $light-yellow 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-bottom: 1px;
  height: 50%;
}

.afternoon-card {
  background: #BFE8EB 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-top: 1px;
  height: 50%;
}

.afternoon {
  height: 50%;
  margin-right: 5px;
  margin-left: 0;
}

.calendar-element {
  width: 100%;
  height: 100%;
  margin-top: 5px;
  background: $light-yellow 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.calendar-element-text {
  font-size: 12px !important;
  color: #474F5E;
}

.card-locked {
  cursor: not-allowed;
  background: $light-yellow 0% 0% no-repeat padding-box;
}

.navigation-header {
  display: flex;
  margin-top: 15px;
  font-family: "Helvetica Neue", helvetica, arial, sans-serif;
  font-size: 16px;
  color: #474F5E;
  font-weight: bold;
}

.navigation-header > * {
  margin-right: var(--space-md);
}

.navigation-button-container, .navigation-button {
  height: 20px;
  min-height: 20px;
  min-width: 20px;
}
.navigation-button {
  cursor: pointer;
  filter: invert(40%) sepia(94%) saturate(713%) hue-rotate(144deg) brightness(98%) contrast(101%);
}

.navigation-month-year {
  width: 130px;
}

.day-number {
  width: 10% !important;
}

.day-text {
  height: 100%;
  width: 12% !important;
}

ul.no-bullets {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

ul.no-bullets li {
  text-align: left;
  font: normal normal normal 12px/16px Helvetica Neue;
  letter-spacing: 0px;
  color: #474F5E;
}

.card-text {
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cancel-button {
  width: 160px;
  height: 38px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #00A3B1;
  border-radius: 4px;
}

.calendar-title {
  text-align: left;
  font: normal normal bold 40px/56px HelveticaNeueLTStd-Lt !important;
  letter-spacing: 0px;
  color: #262A30 !important;
}

.calendar-subtitle {
  text-align: center;
  font: normal normal normal 20px/28px HelveticaNeueLTStd-Lt !important;
  letter-spacing: 0px;
  color: #262A30 !important;
  float: left;
  margin-left: 15px;
  margin-top: 14px;
}
