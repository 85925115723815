@import "theme";

.header {
  display: flex;
  height: 52px;
  width: 100%;
  margin-bottom: 15px;

  h1 {
    white-space: nowrap;
  }
}

.owner-name {
  margin-top: 4px;
  font: normal normal normal 16px/22px HelveticaNeueLTStd-MdCn !important;
  border-radius: 4px;
  white-space: nowrap;

  &.blink {
    animation: blink 0.5s;
    animation-iteration-count: 4;
  }

  @keyframes blink {
  	0% { opacity: 0; }
  	49% { opacity: 0; }
  	50% { opacity: 1; }
  }
}

.owner-name {
  margin-bottom: auto;
  padding: .4em 20px .1em 20px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0px;
  color: #474F5E;
  margin-left: auto;
  background-color: white;
  text-transform: uppercase;
  border: 1px solid #DADCDF;
  width: min-content;
  justify-content: center;
}
