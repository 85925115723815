.default {
  width: 100%;
  overflow-x: hidden;
  vertical-align: bottom;

  .animation {
    width: 650px;
    position: relative;
    animation-timing-function: linear;
    animation-duration: 2s;
    animation-iteration-count: infinite;
  }

  .playing {
    animation-name: scroll;
  }

  .paused {
    animation-name: none;
  }

  @keyframes scroll {
    from { left: -261px; }
    to   { left: 0px; }
  }
}
