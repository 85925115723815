@import "theme";

.icon {
  width: 24px;
  margin: 0rem 0.5rem 0.1rem 0rem;
  filter: $filter-white;
}

.button:not(:hover) .icon.outline {
  filter: $filter-primary;
}
