@import 'theme';

.default {
  position: relative;
  background-color: var(--grid-foreground-color);
  display: flex;
  flex-direction: column;
  overflow: hidden;

  // Top left
  &:nth-child(1), &:nth-child(1) > .wrapper {
    border-radius: 8px 0 0 0;
  }

  // Top right
  &:nth-child(7), &:nth-child(7) > .wrapper {
    border-radius: 0 8px 0 0;
  }

  // Bottom left
  &:nth-child(29), &:nth-child(29) > .wrapper {
    border-radius: 0 0 0 8px !important;
  }

  // Bottom right
  &:last-child, &:last-child > .wrapper {
    border-radius: 0 0 8px 0;
  }

  &:nth-child(7n) > .wrapper:nth-child(1n) {
    border-right: none !important;
  }

  .wrapper {
    flex: 1;
    min-height: 0;
    position: relative;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: #DADCDF;
    overflow: hidden;
  }

  .main {
    display: flex;
    position: relative;
    width: 100%;
    height: 100%;
    max-height: 100%;
    overflow: hidden;
  }

  .body {
    flex: 0 1 100%;
    display: flex;
    height: 100%;
    max-width: 100%;
    flex-wrap: wrap;
    align-items: flex-start;
    position: relative;
  }
}
