.default {
  position: absolute;
  width: 12px;
  right: 11px;
  top: 6px;
}

.icon {
  max-width: 12px;
  height: 12px;
}
