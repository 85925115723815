@import "theme";

.background {
  position: absolute;
  left: -20px;
  top: -20px;
  height: 37%;
  width: calc(100% + 20px);
  background-color: $primary-light;
}

.card {
  width: 360px;
  height: 480px;
  position: absolute;
  left: calc(50% - 180px);
  top: calc(50% - 240px);
  border-radius: 8px;
  background-color: white;
  border: 1px solid $light-grey;
  padding: 1.5rem 1.5rem 1.5rem 1.5rem;
  box-shadow: 0px 3px 6px #00000029;
}
