@import "theme";

.form-label {
  color: $text-color;
  margin-bottom: 0.5rem;
}

.error-message {
  padding-top: 0.5rem;
  padding-left: 0.5rem;
  color: $secondary;
}

.error {
  input, select, .file-input-browse {
    border-color: $secondary;
  }
}

.field {
  min-width: 26rem;
  width: 26rem;
}

.field-small {
  min-width: 13rem;
  width: 13rem;
}

.hidden-file-input {
  display: none;
}

.file-input {
  background-color: #FFF !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  border-right-width: 0 !important;
  text-overflow: ellipsis;
}

.file-input-browse {
  border-radius: .2rem !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  float: right;
  min-width: 2.5rem;
  background: #DADCDF 0% 0% no-repeat padding-box;
  width: 100px;
}
