// We add "x" prefix to prevent collision with current 'icon' class.
//
// TODO:
//  - Replace all `icon-*` classes to use `x-icon-*` instead.
//  - Rename `x-icon-*` classes to `icon-*` classes.
@import 'theme';

.x-icon {
  mask-size: 100%;
  mask-repeat: no-repeat;
  mask-position: center;

  display: inline-block;
  min-height: 24px;
  min-width: 24px;

  background-color: $font-color;

  &.x-icon-small {
    min-height: 19px;
    min-width: 19px;
    margin-right: 6px;
  }
}

.x-icon-lock-close {
  mask-image: url("../public/icons/linear/0108-lock.svg");
}

.x-icon-lock-open {
  mask-image: url("../public/icons/opened-lock.svg");
}

.x-icon-sign-out {
  mask-image: url("../public/icons/linear/0212-exit.svg");
}

.x-icon-hamburger {
  mask-image: url("../public/icons/hamburger.svg");
}

.x-icon-cart {
  mask-image: url("../public/icons/linear/0334-cart.svg");
}

.x-icon-tag {
  mask-image: url("../public/icons/linear/0342-tag.svg");
}

.x-icon-cash-euro {
  mask-image: url("../public/icons/linear/0348-cash-euro.svg");
}

.x-icon-truck {
  mask-image: url("../public/icons/truck.svg");
}

.x-icon-files {
  mask-image: url("../public/icons/0184-files-mirrored.svg");
}

.x-icon-sync {
  mask-image: url("../public/icons/linear/0731-sync.svg");
}

.x-icon-file-check {
  mask-image: url("../public/icons/linear/0182-file-check.svg");
}

.x-icon-warning {
  mask-image: url("../public/icons/linear/0854-warning.svg");
  background-color: $secondary;
}

.x-icon-cog {
  mask-image: url("../public/icons/linear/0116-cog2.svg");
}

.x-icon-check {
  mask-image: url("../public/icons/linear/0821-check.svg");
}

.x-icon-question-circle {
  mask-image: url("../public/icons/question-circle-dj.svg");
}

.x-icon-question-circle-inverted {
  mask-image: url("../public/icons/question-circle-inverted.svg");
}

.x-icon-plus {
  mask-image: url("../public/icons/linear/0823-plus.svg");
}

.x-icon-sort-asc {
  transform: scaleY(-1);
  mask-image: url("../public/icons/chevron-down.svg");
}

.x-icon-sort-desc {
  mask-image: url("../public/icons/chevron-down.svg");
}

.x-icon-chevron-left {
  mask-image: url("../public/icons/linear/0828-chevron-left.svg");
}

.x-icon-chevron-right {
  mask-image: url("../public/icons/linear/0829-chevron-right.svg");
}

.x-icon-magnifier {
  mask-image: url("../public/icons/linear/0803-magnifier.svg");
}

.x-icon-external-link {
  mask-image: url("../public/icons/double-arrow-right.svg");
  min-height: 10px;
  min-width: 10px;
  margin-left: 6px;
  margin-top: 2px;
}

.x-icon-trash {
  mask-image: url("../public/icons/linear/0130-trash2.svg");
}

.x-icon-date {
  mask-image: url("../public/icons/edit-date.svg");
}

.x-icon-calendar {
  mask-image: url("../public/icons/linear/0393-calendar-31.svg");
}

.x-icon-pallet {
  mask-image: url("../public/icons/pallet.svg");
}

.x-icon-home {
  mask-image: url("../public/icons/linear/0002-home2.svg");
}

a {
  .x-icon {
    background-color: $primary;
  }

  &:hover {
    .x-icon {
      background-color: $secondary;
    }
  }
}
