$shadow-color: #00000029;
$warning-bg: #FFFAED;
$warning-border-color: #FFECBE;
$warning-text-color: #474F5E;

.tooltip {
  opacity: 1 !important;

  .tooltip-inner {
    padding: 10px 16px;
    max-width: 300px;
    font-size: 13px;
    letter-spacing: 0;
    text-align: left;
  }
}

.tooltip.tooltip-warning {
  .tooltip-inner {
    background-color: $warning-bg;
    border: solid 1px $warning-border-color;
    color: $warning-text-color;
    box-shadow: 0px 3px 6px $shadow-color;
  }

  .tooltip-arrow::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    border-width: 0.4rem;
    border-style: solid;
    border-color: transparent;
  }

  &.bs-tooltip-top .tooltip-arrow {
    &::before {
      border-top-color: $warning-border-color;
      top: 0;
    }

    &::after {
      border-top-color: $warning-bg;
      top: -1.5px;
      border-bottom-width: 0;
    }
  }

  &.bs-tooltip-bottom .tooltip-arrow {
    &::before {
      border-bottom-color: $warning-border-color;
      bottom: 0;
    }

    &::after {
      border-bottom-color: $warning-bg;
      bottom: -1.5px;
      border-top-width: 0;
    }
  }

  &.bs-tooltip-start .tooltip-arrow {
    &::before {
      border-left-color: $warning-border-color;
      left: 0;
    }

    &::after {
      border-left-color: $warning-bg;
      left: -1.5px;
      border-right-width: 0;
    }
  }

  &.bs-tooltip-end .tooltip-arrow {
    &::before {
      border-right-color: $warning-border-color;
      right: 0;
    }

    &::after {
      border-right-color: $warning-bg;
      right: -1.5px;
      border-left-width: 0;
    }
  }
}
