@import "theme";

.flash {
  padding: 0.7rem 1rem 0.7rem 0.7rem;
  display: inline-block;
  border-radius: 5px;
  margin: 0.15rem 0 0.75rem;
}

.icon {
  margin: 0rem 0.5rem 0.2rem;
  filter: $filter-font-color;
}

.info {
  border: 1px solid $light-grey;
  background-color: $primary-light;
}

.error {
  border: 1px solid $secondary;
  background-color: $secondary-light;

  .icon {
    filter: $filter-secondary;
  }
}
