@import "theme";

.wizard-steps ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.wizard-steps {
  margin-left: 20px;
  margin-top: 4px;
  margin-bottom: auto;
  padding-left: 20px;
  font-size: 16px;
  text-align: center;
  font: normal normal normal 16px/22px HelveticaNeueLTStd-Cn !important;
  letter-spacing: 0px;
  color: #474F5E;
  text-transform: uppercase;
}

.wizard-steps li {
  margin: 0 -20px 0 0;
  float: left;
  text-decoration: none;
  position: relative;
  z-index: 1;
  border-radius: 5px 20px 20px 5px;
}

.wizard-steps li:nth-child(1) {
  z-index: 3 !important;
  padding-left: 18px;
}
.wizard-steps li:nth-child(2) {
  z-index: 2 !important;
  border-left: none !important;
  padding-left: 34px;
}
.wizard-steps li:nth-child(3) {
  width: auto !important;
  border-left: none !important;
  padding-left: 38px
}

.wizard-steps .active {
  text-align: center;
  font: normal normal normal 16px/22px HelveticaNeueLTStd-BdCn !important;
  letter-spacing: 0px;
  color: #474F5E;
  background: #F1FAFB 0% 0% no-repeat padding-box;
}

.wizard-steps .inactive {
  background-color: #FFF !important;
}

.wizard-steps li {
  margin-bottom: auto;
  padding: .4em 20px .1em 20px;
  font-size: 16px;
  text-align: center;
  letter-spacing: 0px;
  color: #474F5E;
  margin-left: auto;
  background-color: white;
  text-transform: uppercase;
  border: 1px solid #DADCDF;
  width: min-content;
  justify-content: center;
}
