@import 'theme';

$fade-duration: 500ms;

@keyframes fadeIn {
  0%   { opacity: 0; }
  100% { opacity: 1; }
}

@keyframes rotation {
  from { transform: rotate(0deg); }
  to { transform: rotate(359deg); }
}

.default {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;

  background-color: #F1FAFB;

  font-family: HelveticaNeueLTStd-Lt;
  font-weight: bold;
  font-size: 16px;
  color: $text-color;

  display: flex;
  justify-content: center;
  align-items: center;

  transition: opacity $fade-duration;
}

.validating {
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity $fade-duration;

  span {
    margin-top: 4px;
  }
}

.cog {
  animation: rotation 3s infinite linear;
  margin-right: 5px;
  background-color: $text-color;
}

.fixed {
  display: flex;
  color: $primary;

  i {
    height: 20px;
    width: 20px;
  }

  span {
    margin-top: 4px;
  }
}

.check {
  background-color: $primary;
  margin-right: 5px;
}

.fadeIn {
  animation: fadeIn $fade-duration;
}

.fadeOut {
  opacity: 0;
}
