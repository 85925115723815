@import 'theme';
@import './variables.scss';

.sidebar {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: transparent;
  border-right: solid 1px transparent;
  padding-top: 28px;

  transition: width $sidebar-animation-speed ease-out, background-color 500ms ease-out, border-color 500ms ease-out;
}

.menuButton {
  width: 24px;
  height: 24px;
  margin-left: $sidebar-content-x-margin;
  margin-bottom: 20px;

  i {
    background-color: #707070;
  }
}

.icon {
  vertical-align: middle;
}

.collapsed {
  width: $sidebar-collapsed-width;
}

.expanded {
  width: $sidebar-expanded-width;
  background-color: $light-blue;
  border-color: #BFE8EB;
}

.divider {
  background-color: #BFE8EB;
  height: 1px;
  margin-top: 10px;
  width: calc($sidebar-expanded-width - $sidebar-content-x-margin * 2);
}

.items {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 2;
  height: 1px;
}

.link {
  display: flex;
  align-items: center;
  font-size: 16px;
  padding: 10px $sidebar-content-x-margin;
  color: $sidebar-link-color !important;
  white-space: nowrap;

  &:visited { color: $sidebar-link-color; }

  &:hover {
    background-color: #BFE8EB;
    i { background-color: $sidebar-link-color; }
  }

  i {
    margin-right: 8px;
    background-color: $sidebar-link-color;
    &:hover { background-color: $sidebar-link-color; }
  }
}
