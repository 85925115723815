@import "theme";

.repair-tables {
  .error-summary {
    margin-bottom: 40px;
  }
  > div:not(:last-child) {
    margin-bottom: 4.5rem;
  }
}
