@import 'theme';

.inputGroup {
  border: solid 1px $primary;
  width: fit-content;
  border-radius: 5px;

  button {
    border-radius: 0;
    color: $primary;
    outline: none;

    &:hover {
      color: $primary;
    }
  }
}
