@import 'theme';

.default {
  &.clickable {
    a {
      color: $text-color !important;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &.disabled {
    cursor: not-allowed;
  }
}
