@import "theme";

.default {
   height: 100%;

  .content-container {
    width: 100%;

    .content {
      display: inline-block;
      width: 100%;
    }
  }

  .header {
    color: $secondary;
  }

  .buttons {
    grid-gap: 1rem;

    button {
      width: 100%;
    }
  }
}
