$primary: #00A3B1;
$secondary: #ED691C;
$primary-light: #F3FBFC;
$secondary-light: #FEF7F3;
$light-grey: #DADCDF;
$font-color: #262A30;
$text-color: #474F5E;

$light-yellow: #FFECBE;
$light-blue: #F2FAFB;

/* filters for changing svg colors, see https://isotropic.co/tool/hex-color-to-css-filter/ */
$filter-white: invert(99%) sepia(0%) saturate(1180%) hue-rotate(198deg) brightness(117%) contrast(100%);
$filter-primary: invert(58%) sepia(93%) saturate(3355%) hue-rotate(149deg) brightness(88%) contrast(101%);
$filter-secondary: invert(52%) sepia(44%) saturate(4559%) hue-rotate(352deg) brightness(97%) contrast(91%);
$filter-font-color: invert(15%) sepia(14%) saturate(512%) hue-rotate(177deg) brightness(93%) contrast(95%);

// override some datepicker setting variables
$datepicker__text-color: $font-color !default;
$datepicker__header-color: $font-color !default;
$datepicker__selected-color: $font-color !default;
$datepicker__muted-color: $primary !default;
$datepicker__navigation-button-size: 46px !default;

@import '~react-datepicker/src/stylesheets/datepicker.scss';

@font-face {
  font-family: HelveticaNeueLTStd-BdCn;
  src: url("fonts/HelveticaNeueLTStd-BdCn.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: HelveticaNeueLTStd-Lt;
  src: url("fonts/HelveticaNeueLTStd-Lt.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: HelveticaNeueLTStd-Cn;
  src: url("fonts/HelveticaNeueLTStd-Cn.ttf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: HelveticaNeueLTStd-MdCn;
  src: url("fonts/HelveticaNeueLTStd-MdCn.otf") format("opentype");
  font-display: swap;
}
@font-face {
  font-family: Inter;
  src: url("fonts/Inter-VariableFont_slnt,wght.ttf") format("truetype");
  font-display: swap;
}

