@import 'theme';

.default {
  padding: 0.25rem;
  height: 100%;

  .card {
    height: 100%;
    border-radius: 5px;
    opacity: 1;
    text-align: left;
    font: 12px Helvetica Neue;
    letter-spacing: 0px;
    color: #474F5E;
    padding-left: 6px;
    padding-top: 1px;
    margin-left: 1.75rem;

    &.open {
      background: #BFE8EB 0% 0% no-repeat padding-box;
    }

    &.confirmed {
      background: #E8F0A0 0% 0% no-repeat padding-box;
    }
  }
}


