@import "theme";

.order-filter-form {
  color: #474F5E;
  display: flex;
  gap: 24px;

  label {
    width: calc(100% - 352px - 354px - (24px)*2);
    max-width: 173px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .queryInput {
    width: 352px;
    min-width: 352px;
  }

  @media (max-width: 1160px) {
    label {
      display: none;
    }
    .queryInput {
      width: calc(100% - 354px - 24px);
      min-width: auto;
    }
  }

  .filterStatusButtons {
    width: 354px;

    button {
      color: #474F5E;
      width: 118px;

      &.selected, &:hover {
        color: white !important;
        border-color: $primary !important;
        background-color: $primary !important;
      }
    }
  }


  .no-br {
    border-right: none;
  }

  .transparent {
    border-left: none;
    background-color: transparent;
  }

  .grey {
    filter: invert(94%) sepia(9%) saturate(156%) hue-rotate(171deg) brightness(87%) contrast(97%);
  }
}
