@import "theme";

.threeColumns {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 1.5em;
  color: #262A30;
  min-width: 1284px;
}

.illustration {
  position: absolute;
  top: -15px;
  left:  calc(100% - 330px);
}

.divider {
  border-left:1px solid #DADCDF;
  height: 40px;
  margin-top: 8px;
  margin-left: 14px;
}
