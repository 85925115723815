@import "theme";

.ordersContainer {
  display: flex;
  gap: 2%;

  .orders {
    $min-order-detail-width: 320px;
    width: calc(100% - $min-order-detail-width);
  }
}

.orders-table {
  th {
    padding-right: 0 !important;
    padding-left: 0 !important;
    color: #00A3B1;

    &.sku, &.ean, &.owner_reference, &.ordered_on {
      max-width: 170px;
    }
    &.ordered_on {
      padding-right: 15px;
    }

    &:hover {
      cursor: pointer;
    }

    .sort_icon {
      min-height: 10px;
      min-width: 10px;
      margin-left: 5px;
      filter: invert(36%) sepia(78%) saturate(2226%) hue-rotate(159deg) brightness(100%) contrast(101%);
    }
  }

  tbody {
    border-top: 1px solid #474F5E !important;

    tr {
      border-bottom: solid 1px #DADCDF;
      opacity: 1;
      -webkit-transition: opacity 500ms linear;
      transition: opacity 500ms linear;

      &.highlightedRows {
        background-color: $light-blue;
        td {
          font: normal 16px/49px HelveticaNeue-Medium;
        }
      }

      &.fadeOut {
        opacity: 0;
      }

      &:hover {
        background-color: #BFE8EB;
        cursor: pointer;
      }

      td {
        color: #474F5E;
        font: normal 16px/49px Helvetica Neue;
        padding: 0 20px 0 0 !important;

        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        max-width: 80px;

        &.titleNoOverflow {
          width: 40%;
        }

        &.remove {
          text-align: center;
          background-color: $light-blue;
          font: normal 16px/49px HelveticaNeue-Medium;
          text-transform: uppercase;
        }

        &:last-child {
          padding-right: 0 !important;
        }

        a {
          color: #474F5E;
          &:hover {
            color: #474F5E;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
