.default {
  position: relative;
  flex: 0 0 100%;
  max-height: 100%;
  height: 50%;
  width: 100%;
  margin-right: 0;
  padding: 0;
  overflow: hidden;

  &:last-child {
    border-top: 1px dotted #DADCDF;
  }
}
