.default {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block !important;
  min-height: 40px;
}

.selected {
  font-weight: bold;
}

.scrollable {
  display: flex;
  overflow-y: auto;
  flex-direction: column-reverse;
}
.defaultHeight {
  height: calc(100vh - 300px);
}
.emptyHeight {
  height: calc(100vh - 100px);
}
