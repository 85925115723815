@import "theme";

.default {
  height: 100%;

  &:hover {
    background: $light-blue 0% 0% no-repeat padding-box;
    cursor: pointer;
  }

  &.locked {
    &:hover {
      cursor: not-allowed !important;
    }
  }

  &.wait {
    cursor: wait !important;
    background: #BFE8EB 0% 0% no-repeat padding-box !important;
  }
}
