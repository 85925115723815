@import './variables.scss';

.content {
  padding: 20px;
  transition: margin $sidebar-animation-speed ease-out;
}

.default {
  margin-left: $sidebar-collapsed-width;
}

.pushed {
  margin-left: $sidebar-expanded-width;
}
