.default {
   margin-top: 2.5rem;
   height: 100%;

  .content-container {
    width: 100%;

    .content {
      display: inline-block;
      width: 100%;
    }
  }
}
