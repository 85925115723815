@import "custom-bootstrap";

.default {
  background-image: url('../../../../../public/boxes.svg');
  background-repeat: no-repeat;
  background-position: 100% 0;
  margin-top: -20px;
  margin-right: -20px;
  padding-right: 20px;
}

.header {
  padding-top: 20px;
  @extend .pb-1;
}
