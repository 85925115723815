@import "theme";
@import "custom-bootstrap";
@import 'icons.scss';
@import 'tooltips.scss';

html {
  overflow-x: hidden;
}

.App {
  font-family: Inter;
  color: $font-color !important;

  a {
    color: $primary;
    text-decoration: none;

    .icon {
      filter: $filter-primary;
    }

    &:hover {
      color: $secondary;

      .icon {
        filter: $filter-secondary;
      }
    }
  }

  table {
    td, th {
      font-family: Helvetica Neue;
      font-weight: normal;
      padding: .9rem;
    }

    tr:last-child {
      td, th {
        border-bottom: none;
      }
    }
  }

  .form-control, .table {
    color: $font-color;
  }

  h1, h2, h3 {
    font-family: HelveticaNeueLTStd-BdCn;
  }

  h1 {
    .subtitle {
      font-family: HelveticaNeueLTStd-Lt;
      color: $secondary;
      text-transform: uppercase;
      font-size: 1.8rem;
    }
    padding-bottom: 0.25rem;
  }

  .btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:visited, .btn-outline-primary:hover {
    color: white !important;
  }

  .icon {
    &.text-primary { filter: $filter-primary }
    &.text-secondary { filter: $filter-secondary }
  }

  table {
    td, th {
      font-family: Helvetica Neue;
      font-weight: normal;
      padding: .9rem;
    }

    tr:last-child {
      td, th {
        border-bottom: none;
      }
    }
  }

  .btn-primary:hover, .btn-outline-primary:hover {
    border-color: $secondary;
    background-color: $secondary;
  }

  a, a:visited {
    color: $primary;
  }

  a:hover {
    color: $secondary;
  }
}
